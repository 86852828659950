<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="fundExitSn"
                    label="出境单号"
                >
                    <el-input
                        v-model="queryFormModel.fundExitSn"
                        placeholder="请输入出境单号"
                    />
                </el-form-item>
                <el-form-item
                    prop="paymentSn"
                    label="付款单号"
                >
                    <el-input
                        v-model="queryFormModel.paymentSn"
                        placeholder="请输入付款单号"
                    />
                </el-form-item>
                <el-form-item
                    prop="externalPaymentSn"
                    label="第三方付款单号"
                >
                    <el-input
                        v-model="queryFormModel.externalPaymentSn"
                        placeholder="请输入第三方付款单号"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderSn"
                    label="订单号"
                >
                    <el-input
                        v-model="queryFormModel.orderSn"
                        placeholder="请输入订单号"
                    />
                </el-form-item>
                <el-form-item
                    prop="fundExitStatus"
                    label="出境状态"
                >
                    <dictionaries-picker
                        type="fundExitStatus"
                        v-model="queryFormModel.fundExitStatus"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderStatus"
                    label="订单状态"
                >
                    <dictionaries-picker
                        type="orderStatus"
                        v-model="queryFormModel.orderStatus"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-upload2"
                        size="small"
                        @click="onBatchSubmit"
                        :disabled="!currentTableSelect.length"
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    prop="fundExitSn"
                    label="出境单号"
                    min-width="100"
                />
                <el-table-column
                    prop="exFundExitSn"
                    label="第三方出境单号"
                    min-width="100"
                />
                <el-table-column
                    prop="paymentSn"
                    label="付款单号"
                    width="180"
                />
                <el-table-column
                    prop="externalPaymentSn"
                    label="第三方付款单号"
                    width="210"
                />
                <el-table-column
                    prop="paymentTypeName"
                    label="付款方式"
                    width="70"
                />
                <el-table-column
                    label="付款时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.paymentTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderSn"
                    label="订单号"
                    width="120"
                />
                <el-table-column
                    label="下单时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.orderTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="完成时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.receiveTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderStatusName"
                    label="订单状态"
                    width="70"
                />
                <el-table-column
                    label="订单金额"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        <div>订单金额：{{ scope.row.orderAmount }}</div>
                        <div>退款金额：{{ scope.row.refundAmount }}</div>
                        <div>可出境金额：{{ scope.row.orderAmount - scope.row.refundAmount }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="出境金额"
                    min-width="150"
                >
                    <template
                        slot-scope="scope">
                        <div>出境比例：{{ scope.row.fundExitRate }}%</div>
                        <div>出境金额：{{ scope.row.amount }}</div>
                        <div>收款币种：{{ scope.row.foreignCurrency }}</div>
                        <div>外币金额：{{ scope.row.foreignAmount }}</div>
                        <div>汇率：{{ scope.row.rate }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="fundExitStatusName"
                    label="出境状态"
                    min-width="100"
                />
                <el-table-column
                    prop="exceptionInfo"
                    label="出境异常信息"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onSubmit(scope.row.id)"
                            v-if="scope.row.fundExitStatus === 10"
                        >
                            出境
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onReSubmit(scope.row.id)"
                            v-if="scope.row.fundExitStatus === 90"
                        >
                            重新出境
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Variable',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                fundExitSn: '',
                paymentSn: '',
                orderSn: '',
                externalPaymentSn: '',
                fundExitStatus: '',
                orderStatus: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.OrderFundExit.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onBatchSubmit() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onSubmit(ids.join(','));
        },
        onSubmit(ids) {
            this.$confirm('是否确定要出境！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ex.OrderFundExit.submit({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onReSubmit(ids) {
            this.$confirm('是否确定要重新出境！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ex.OrderFundExit.submit({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
